import axios from "axios";
import gsap from "gsap";

function anim(animArray) {
  gsap.fromTo(
    animArray,
    {
      y: 10,
      autoAlpha: 0,
    },
    {
      y: 0,
      autoAlpha: 1,
      duration: 0.6,
      stagger: 0.1,
      ease: "power1.out",
    }
  );
}

export default function () {
  document.addEventListener("click", function(e) {
    const target = e.target;
    const loadMore = target.closest("#load-more") ;
    if (!loadMore) return;
    e.preventDefault();
    document.body.classList.add("loading-more-items");
    const href = loadMore.href;
    axios.get(href).then(function (res) {
      // Parse the returned HTML
      const parser = new DOMParser();
      const resHTML = parser.parseFromString(res.data, "text/html");
      const newGrid = resHTML.getElementById("load-more-grid");
      const newModals = resHTML.getElementById("load-more-modals");
      const currentGrid = document.getElementById("load-more-grid");
      const currentModals = document.getElementById("load-more-modals");
      // Add temporary class to new cards in so we only animate those/don't re-animate existing cards:
      const newAnimateEls = newGrid.querySelectorAll(".js-anim-batch");
      newAnimateEls.forEach((card) => card.classList.add("js-ajax-anim"));
      currentGrid.insertAdjacentHTML("beforeend", newGrid.innerHTML);
      if (currentModals) {
        currentModals.insertAdjacentHTML("beforeend", newModals.innerHTML);
      }
      const addedItems = document.querySelectorAll(".js-ajax-anim");
      addedItems.forEach((card) => card.classList.remove("js-ajax-anim"));
      anim(addedItems);
      loadMore.parentElement.remove();
      document.body.classList.remove("loading-more-items");
    })
    .catch(function (err) {
      console.log(err);
      document.body.classList.remove("loading-more-items");
    });
  });
}
