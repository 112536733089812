document.documentElement.classList.remove("no-js");

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

import home from "./modules/home";
import homeCarousel from "./modules/home-carousel";
import cardCarousel from "./modules/card-carousel";
import innovateCarousel from "./modules/innovate-carousel";
import filterCarousel from "./modules/filter-carousel";
import accordions from "./modules/accordions";
import header from "./modules/header";
import carousel from "./modules/carousel";
import matrixImageCarousel from "./modules/matrix-image-carousel";
import video from "./modules/video";
import slider from "./modules/slider-alt";
import reef from "./modules/reef";
import nav from "./modules/nav";
import homeMobile from "./modules/home-mobile";
import form from "./modules/form";
import partners from "./modules/partners";
import resources from "./modules/resources";
import facts from "./modules/facts";
import animations from "./modules/animations";
import storiesModal from "./modules/stories-modal";
import model from "./modules/model";
import map from "./modules/map";
import loadMore from "./modules/load-more";
import filterCards from "./modules/filter-cards";
import paginateBlocks from "./modules/paginate-blocks";
import viewRadio from "./modules/view-radio";

header();
home();
homeCarousel();
cardCarousel();
innovateCarousel();
filterCarousel();
accordions();
homeMobile();
carousel();
matrixImageCarousel();
video();
slider();
reef();
nav();
form();
partners();
resources();
facts();
model();
map();
loadMore();
storiesModal();
filterCards();
paginateBlocks();
viewRadio();

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add("user-is-tabbing");
    window.removeEventListener("keydown", handleFirstTab);
  }
}

window.addEventListener("keydown", handleFirstTab);

window.addEventListener("load", function () {
  if (location.pathname === "/") {
    if ("scrollRestoration" in history) {
      // Back off, browser, I got this...
      history.scrollRestoration = "manual";
      if (window.scrollY) {
        window.scroll(0, 0); // reset the scroll position to the top left of the document.
      }
    }
  }

  document.body.classList.remove("page-is-loading");
  animations.init();
});
