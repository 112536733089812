const activeClass = 'view-is-active';
import gsap from 'gsap';
import animations from './animations';

function initResizeListener() {
  // Setup a timer
  let timeout;
  // Listen for resize events
  window.addEventListener('resize', function ( event ) {
    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }
    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(function () {
      // Run our resize functions
      setMinHeight();
    });
  }, false);
}

// Update container minHeight if screensize changes
function setMinHeight() {
  const container = document.getElementById('views');
  const minHeight = container.offsetHeight;
  gsap.set(container, {minHeight: minHeight});
}

function updateView(e) {
  const radio = e.target.closest('.js-view-radio');
  const matchingView = document.getElementById(`view-${radio.id}`);
  const currentView = document.querySelector(`.${activeClass}`);
  currentView.classList.remove(activeClass);
  const animItems = Array.from(matchingView.querySelectorAll('.js-anim-batch'));
  matchingView.classList.add(activeClass);
  animations.anim(animItems);
}

export default function() {
  const radios = document.querySelectorAll('.js-view-radio');
  if (!radios.length) return;
  // Listen for changes on the radio buttons
  radios.forEach(radio => {
    radio.addEventListener('change', updateView);
  });
  initResizeListener();
  setMinHeight();
}
